<template>
  <b-card 
    img-right 
    :title="t('Certificates')">
      
    <template v-if="certificateLocal.certificates.main.type === ''">
      <b-card-text style="margin-bottom: 78px">
        <em> {{ t('msguploadcsd') }}  </em>
      </b-card-text>
      <b-card-text class="small text-muted">{{ t('msgnouploadcsd') }}</b-card-text>
    </template>
    <template v-else>
      <b-card-text>
        <b-row>
          <b-col cols="4">
            {{ t('Type') }}
          </b-col>
          <b-col cols="8">
            {{ certificateLocal.certificates.main.type === 'csd' ? t('CSD') : t('Fiel') }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            {{ t('Business Name') }}
          </b-col>
          <b-col cols="8">
            {{ certificateLocal.certificates.main.name }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            {{ t('Tax ID') }}
          </b-col>
          <b-col cols="8">
            {{ certificateLocal.certificates.main.taxid }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            {{ t('Serial') }}
          </b-col>
          <b-col cols="8">
            {{ certificateLocal.certificates.main.serial }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            {{ t('Valid Until') }}
          </b-col>
          <b-col cols="8">
            {{ certificateLocal.certificates.main.notAfter }}
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text class="small text-muted">{{ t('Last Update') }} : <em>{{ certificateLocal.certificates.main.udate }}</em></b-card-text>
    </template>

    <b-card-footer>
      <b-row>
        <b-col cols="3">
          <div v-show="!csdCerFileUploaded">
            <b-link
              class="nav-link"
              @click="$refs.refCSDCer.$el.click()"
            >
              <feather-icon
                size="14"
                icon="UploadCloudIcon"
              />
              &nbsp;&nbsp;&nbsp;<span>{{ t('Upload Public Key (*.cer)') }}</span>
            </b-link>
            <b-form-file
              id="pubK"
              ref="refCSDCer"
              v-model="csdCerFile"
              accept=".cer"
              hidden="true"
              plain
              @change="chkPubK"
            />
          </div>
          <div v-show="csdCerFileUploaded" style="display: flex; padding-top: 5px;">
            <feather-icon
              size="14"
              icon="ThumbsUpIcon"
            />
            &nbsp;&nbsp;<span style="margin-right: 10px;">{{ t('Public Key Uploaded') }} </span> <a class="nav-link" href="#" style="display: contents;" @click="resPubK">({{ t('Reset') }})</a>
          </div>
        </b-col>
        <b-col cols="3">
          <div v-show="!csdKeyFileUploaded">
            <b-link
              class="nav-link"
              @click="$refs.refCSDKey.$el.click()"
            >
              <feather-icon
                size="14"
                icon="UploadCloudIcon"
              />
              &nbsp;&nbsp;&nbsp;<span>{{ t('Upload Private Key (*.key)') }}</span>
            </b-link>
            <b-form-file
              id="priK"
              ref="refCSDKey"
              v-model="csdKeyFile"
              accept=".key"
              hidden="true"
              plain
              @change="chkPriK"
            />
          </div>
          <div v-show="csdKeyFileUploaded" style="display: flex; padding-top: 5px;">
            <feather-icon
              size="14"
              icon="ThumbsUpIcon"
            />
            &nbsp;&nbsp;<span style="margin-right: 10px;">{{ t('Private Key Uploaded') }} </span> <a class="nav-link" href="#" style="display: contents;" @click="resPriK">({{ t('Reset') }})</a>
          </div>
        </b-col>
        <b-col cols="3">
          <b-input-group v-if="csdPassFieldActive === true" class="input-group-merge">
            <b-form-input
              id="pass"
              v-model="csdPass"
              :type="csdPassFieldType"
              name="retype-password"
              :placeholder="t('Key Password')"
              @update="checkPassData"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="csdPassFieldIcon"
                class="cursor-pointer"
                @click="toggle_csdPassFieldType"
              />
              
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <b-overlay
            :show="onUpdateBusy"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-link
              v-show="csdPassFieldWithData"
              class="nav-link cursor-pointer"
              @click="checkSignature"
            >
              <feather-icon
                size="14"
                icon="SendIcon"
              />
              &nbsp;&nbsp;&nbsp;<span>{{ t('Send') }}</span>
            </b-link>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card-footer>

  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BOverlay, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
  BInputGroup, BInputGroupAppend, BCardFooter,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { Base64 } from '@core/utils/utils'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Crypto from "@/crypto";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BOverlay,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BCardFooter,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    certificateData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    certificateData (v) {
      this.certificateLocal = JSON.parse(JSON.stringify(v))
    }
  },
  data() {
    return {
      certificateLocal: JSON.parse(JSON.stringify(this.certificateData)),
      csdKeyFile: null,
      csdCerFile: null,
      csdKeyFileB64: null,
      csdCerFileB64: null,
      csdKeyFileUploaded: false,
      csdCerFileUploaded: false,
      csdPass: '',
      csdPassFieldType: 'password',
      csdPassFieldActive: false,
      csdPassFieldWithData: false,
      onUpdateBusy: false,
    }
  },
  mounted() {
    this.$root.$on('session-settings-refresh',(message) => {
      console.log('SettingsCertificate session-settings-refresh', message)
      certificateLocal: JSON.parse(JSON.stringify(this.certificateData))
    })
  },
  computed: {
    csdPassFieldIcon() {
      return this.csdPassFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    toggle_csdPassFieldType() {
      this.csdPassFieldType = this.csdPassFieldType === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.certificateLocal = JSON.parse(JSON.stringify(this.certificateData))
    },
    chkPriK(o) {
      var  result = o.target.files;
      var array  = [].slice.call(result);
      if (array[0] === undefined) {
        this.csdKeyFileUploaded = false;
      } else {
        this.csdKeyFileUploaded = true;
        let reader = new FileReader();
        let _self = this;
        reader.onload = function(e) { _self.csdKeyFileB64 = Base64.encode(e.target.result) };
        reader.readAsBinaryString(array[0]);
      }
      //this.checkSignature()
      this.checkPass()
    },
    chkPubK(o) {
      var  result = o.target.files;
      var array  = [].slice.call(result);
      if (array[0] === undefined) {
        this.csdCerFileUploaded = false;
      } else {
        this.csdCerFileUploaded = true;
        let reader = new FileReader();
        let _self = this;
        reader.onload = function(e) { _self.csdCerFileB64 = Base64.encode(e.target.result) };
        reader.readAsBinaryString(array[0]);
      }
      this.checkPass()
    },
    resPubK() {
      this.csdCerFile = []
      this.csdCerFileUploaded = false
      this.checkPass()
    },
    resPriK() {
      this.csdKeyFile = []
      this.csdKeyFileUploaded = false
      this.checkPass()
    },
    chkPass(o) {
      if (o === undefined) {
        this.pass = false;
      } else {
        this.pass = true;
      }
      this.checkSignature()
    },
    checkPass() {
      if(this.csdKeyFileUploaded && this.csdCerFileUploaded) 
        this.csdPassFieldActive = true
      else {
        this.csdPassFieldActive = false
        this.csdPass = '';
      }
    },
    checkPassData() {
      if(this.csdPass !== '')
        this.csdPassFieldWithData = true
      else
        this.csdPassFieldWithData = false
    },
    checkSignature() {
        this.onUpdateBusy = true
        const crypto = new Crypto(this.$el.querySelector('#pubK'));
        crypto.getCertData()
          .then((result) => {
            let session = JSON.parse(localStorage.getItem('session'))

            console.log('SettingsCertificate onSign', result)

            if(session.business.taxid !== result.data.rfc) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('Review your Certificate Data'),
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                  text: this.$t('Tax Id not found'),
                },
              })
              this.onUpdateBusy = false
              return
            } 

            if(session.business.type === 'pf') {
              if(session.business.name !== result.data.name) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('Review your Certificate Data'),
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                    text: this.$t('Business Name and CSD Name doesnt match'),
                  },
                })
                this.onUpdateBusy = false
                return
              } 
            } else if (session.business.type === 'pm') {
              if(!result.data.name.includes(session.business.name)) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('Review your Certificate Data'),
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                    text: this.$t('Business Name and CSD Name doesnt match'),
                  },
                })
                this.onUpdateBusy = false
                return
              } 
            }
              
            /**if(result.certificate.data.unit === '') {
              this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('Review your Certificate Data'),
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                    text: this.$t('Certificate isn\'t supported because it\'s FIEL'),
                  },
                })
                this.onUpdateBusy = false
                return
            }**/

              let csdRequest = {
                type: result.data.unit !== '' ? 'csd' : 'fiel',
                serial: result.data['serial-number'],
                name: result.data.name,
                taxid: result.data.rfc,
                notAfter: result.data.notAfter,
                notBefore: result.data.notBefore,
                bundle: {
                  pubK: this.csdCerFileB64,
                  priK: this.csdKeyFileB64,
                  pass: Base64.encode(this.csdPass)
                }
              }

              //console.log('bundle',csdRequest);

              this.$http.post('/account/certificate/update', { csd: csdRequest })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('Data Updated'),
                    icon: 'StarIcon',
                    variant: 'success',
                    text: this.$t('Certificate success update'),
                  },
                })
                // Reset the component
                this.csdKeyFile = null
                this.csdCerFile = null
                this.csdKeyFileB64 = null
                this.csdCerFileB64 = null
                this.csdKeyFileUploaded = false
                this.csdCerFileUploaded = false
                this.csdPass = ''
                this.csdPassFieldType = 'password'
                this.csdPassFieldActive = false
                this.csdPassFieldWithData = false

                /** Updates the main session in settings */
                this.$root.$emit('session-refresh',{})

                this.onUpdateBusy = false

              })
              .catch(error => {
                console.group('fg.views.settings.general')
                console.error('error',error.response)
                console.groupEnd()

                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('Error while uploading Business Data'),
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                    text: this.$t('The CSD Certificate cant be updated, because: ') + error.response.data.message,
                  },
                })

                this.onUpdateBusy = false
              })
            

          })
          .catch((error) => {
            console.log("error", error);
            let textRoast = '';

            switch (error) {
              case "Certs expired":
              case "Certs not valid yet":
                textRoast = this.$t('Certificate is due')
                break;
              case "Certs not match":
              case "Bad private key":
              case "Bad public key":
              case "Private Key password incorrect":
                textRoast = this.$t('Certificate Password')
                break;
              default:
                textRoast = this.$t('Unknown error') + ' ' + error
                break;
            }


            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('Review your Certificate Data'),
                icon: 'AlertTriangleIcon',
                variant: 'warning',
                text: textRoast,
              },
            })

            this.onUpdateBusy = false
          })
    }
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
}
</script>
