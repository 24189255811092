import {
    asn1 as ASN1,
    pki as PKI,
} from "node-forge";

class Crypto{
    #publicKey;

    constructor(publicKey) {
        this.#publicKey = publicKey;
    }

    #loadPublicKey() {
        return new Promise((resolve, reject) => {

            if (this.#publicKey.files.length === 0) {
                reject("Bad public key");
            }

            let reader = new FileReader();

            reader.onloadend = () => {
                try {
                    const bytes = reader.result;

                    const asnSequence = ASN1.fromDer(bytes);

                    const certKey = PKI.certificateFromAsn1(asnSequence);

                    resolve(certKey);
                } catch (e) {
                    reject("Bad public key");
                }
            }
            reader.readAsBinaryString(this.#publicKey.files[0]);

        });
    }


    async getCertData() {
        const certKey = await this.#loadPublicKey();
        const subject = certKey.subject;

        const attributes = subject.attributes;

        let certData = {};

        for (let i = 0; i < attributes.length; i++) {
            const attribute = attributes[i];

            if (attribute.type === '2.5.4.3') {
                certData['name'] = attribute.value;
                continue;
            }

            if (attribute.type === '1.2.840.113549.1.9.1') {
                certData['email'] = attribute.value;
                continue;
            }
            if (attribute.type === '2.5.4.45') {
                let rfc = attribute.value;
                if (rfc.indexOf("/") !== -1) {
                    certData['rfc_legal'] = rfc.substring(rfc.indexOf("/") + 1).trim();
                    rfc = rfc.substring(0, rfc.indexOf("/")).trim();
                }
                certData['rfc'] = rfc;
                continue;
            }
            if (attribute.type === '2.5.4.5') {
                let curp = attribute.value;

                if (curp.indexOf("/") !== -1) {
                    curp = curp.substring(curp.lastIndexOf("/")+1).trim();
                }

                certData['curp'] = curp;
            }
            if (attribute.type === '2.5.4.11') {
                certData['unit'] = attribute.value;
                continue;
            }

            const validity = certKey.validity;
            certData['notBefore'] = validity.notBefore.getTime();
            certData['notAfter'] = validity.notAfter.getTime();

        }

        const tbsCertificates = certKey.tbsCertificate.value;

        for (let i = 0; i < tbsCertificates.length; i++) {
            const tbsCertificate = tbsCertificates[i];

            if (tbsCertificate.type === 2) {
                certData['serial-number'] = tbsCertificate.value;
            }
        }

        const today = new Date().getTime();

        if (  today < certData.notBefore ){
            reject("Certs not valid yet");
        }

        if (  today > certData.notAfter ){
            reject("Certs expired");
        }

        return {
            data: certData
        };
    }

}


export default Crypto;